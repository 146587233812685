import {
  QueryClient as ReactQueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Store the queryClient instance globally
window.reactQueryClient = undefined;

export const defaultQueryClient = new ReactQueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (failureCount > 5 || error?.response?.status < 500) {
          return false;
        }

        return window.__HB_ENV__.ENVIRONMENT === 'production';
      },
      // Never refetch the data if we refocus the window
      refetchOnWindowFocus: false,
    },
  },
});

const getQueryClient = () => {
  if (!window.reactQueryClient) {
    window.reactQueryClient = defaultQueryClient;
  }

  return window.reactQueryClient;
};

const QueryClientProvider = ({ children, queryClient = getQueryClient() }) => (
  <ReactQueryClientProvider client={queryClient}>
    {children}
    <ReactQueryDevtools initialIsOpen={false} />
  </ReactQueryClientProvider>
);

export default QueryClientProvider;
