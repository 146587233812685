import classNames from 'classnames';
import ms from 'ms';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Box } from '@hivebrite/design-system/Box';
import {
  CATEGORIES,
  FontAwesomeIcon,
} from '@hivebrite/design-system/FontAwesomeIcon';
import { Loader } from '@hivebrite/design-system/Loader';
import type { ColorName } from '@hivebrite/design-system/Theme';
import {
  focusOutline,
  responsive,
  ThemeProvider,
  useTheme,
} from '@hivebrite/design-system/Theme';

import type {
  EngineDataLocation,
  IconWrapperProps,
  InputAndDropdownTheme,
  InputLocationProps,
  Location,
} from 'hb-react/frontoffice/components/inputs/InputLocation/types';
import { getDistanceFromBounds } from 'hb-react/frontoffice/components/inputs/InputLocation/utils/getDistanceFromBounds';
import { useDefaultAppContexts } from 'hb-react/frontoffice/contexts/withDefaultAppContexts';
import useInputAndDropdownTheme from 'hb-react/shared/hooks/theme/useInputAndDropdownTheme';
import { withFeatureFlag } from 'hb-react/shared/hooks/useFeatureFlag';
import * as errorReporting from 'hb-react/shared/utils/errorReporting';
import * as mobileDeviceFromUserAgent from 'helpers/mobileDeviceFromUserAgent';

import BingSearch from './engines/bing';
import GoogleSearch from './engines/google';
import MapboxSearch from './engines/mapbox';
import { MIN_LENGTH } from './engines/search';
import InputRadius from './InputRadius';

import style from './index.module.scss';

const CloseIcon = () => (
  <FontAwesomeIcon height="16px" iconName="xmark" iconType={CATEGORIES.SOLID} />
);
const LocationIcon = () => (
  <FontAwesomeIcon height="16px" iconName="location-dot" />
);

export const getEngine = (providerName: InputLocationProps['providerName']) => {
  switch (providerName) {
    case 'google':
      return GoogleSearch;
    case 'bing':
      return BingSearch;
    case 'mapbox':
      return MapboxSearch;
    default:
      throw new Error('Invalid provider name');
  }
};

const InputLocation = ({
  additionalInputs = true,
  ariaDescribedBy = '',
  biasLocationCode,
  className,
  clearable = true,
  defaultLocation = {},
  disabled = false,
  icon: IconComponent = LocationIcon,
  id,
  idAttr = false,
  inputAndDropdownTheme,
  inputHtml,
  inputName,
  label,
  onChange,
  onlineEnabled = false,
  onlineLabel = '',
  onUpdateOnline,
  onSelectLocation,
  placeholder,
  providerName = 'google',
  primaryInputName = 'address',
  radius = {
    enabled: false,
    inputName: '',
  },
  theme,
  variant = 'normal',
}: InputLocationProps) => {
  const { currentNetwork, currentUser } = useDefaultAppContexts();
  const locationInputRef = useRef<HTMLInputElement>(null);

  const defaultAddress = defaultLocation?.[primaryInputName];
  const [canReset, setCanReset] = useState(!!defaultAddress);
  const [displayError, setDisplayError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState<Location>({
    ...defaultLocation,
    address: defaultAddress,
    bias_code: biasLocationCode,
  });

  // @ts-expect-error: is_from_china is not defined
  const provider = window.is_from_china ? 'bing' : providerName;
  const themeInput = theme.input[variant];
  // Coming from SCSS for now (input-with-icon) - to remove once moving to DS select/inputs
  const iconPaddingValue = 40;

  const isClearable = clearable && !disabled && canReset;
  const aggregatedAriaDescribedBy =
    displayError || ariaDescribedBy
      ? [displayError && `${id}-custom-error`, ariaDescribedBy]
          .filter(Boolean)
          .join(' ')
      : undefined;

  const Engine = getEngine(providerName);

  const updateLocationState = useCallback(
    (location: EngineDataLocation) => {
      let callback = onSelectLocation;

      if (!callback) {
        callback = (loc) => {
          const event = new CustomEvent('location:selected', {
            detail: { location: loc },
          });

          document.dispatchEvent(event);
        };
      }

      const distance = getDistanceFromBounds(location.bounds);
      const updatedLocation = {
        ...location,
        location: locationInputRef.current?.value,
        distance,
      };

      setCanReset(!!location.lat);
      setLocation(updatedLocation);

      callback(updatedLocation);

      if (onChange) onChange(updatedLocation);
    },
    [onChange, onSelectLocation],
  );

  const updateOnline = useCallback(
    (onlineSelected: boolean) => {
      if (onUpdateOnline) {
        onUpdateOnline(onlineSelected);
      }
    },
    [onUpdateOnline],
  );

  const resetLocationState = () => {
    setDisplayError(false);

    if (onlineEnabled && locationInputRef.current?.value === onlineLabel) {
      updateOnline(false);
    } else {
      updateLocationState({});
    }

    if (locationInputRef.current) {
      locationInputRef.current.value = '';
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === '') {
      resetLocationState();
    } else {
      setCanReset(!!locationInputRef.current?.value);
    }
  };

  const handleOnKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
    }
  };

  const handleBlur = () => {
    if (onlineEnabled && locationInputRef.current?.value === onlineLabel) {
      return;
    }

    if (!location.lat && locationInputRef.current?.value) {
      setDisplayError(true);
    }
  };

  const initSearch = useCallback(() => {
    const regionCountryCode =
      currentUser?.last_location?.country_code ||
      currentNetwork?.bias_location?.country_code ||
      location.bias_code;

    try {
      const search = new Engine({
        container: locationInputRef.current,
        // Bing provider do not support region
        region: providerName === 'bing' ? undefined : regionCountryCode,
        onlineEnabled,
        onlineLabel,
      });

      search.$container
        .on('typeahead:select', (_event, data) => {
          if (data === onlineLabel) {
            updateOnline(true);
          } else {
            updateOnline(false);
            const locationData = search.data(data);

            if (locationData) {
              updateLocationState(locationData);
            }
          }

          setDisplayError(false);
          setIsLoading(false);
        })
        .on('typeahead:asyncrequest', (_, __, query) => {
          if (query.length >= MIN_LENGTH) {
            setIsLoading(true);
          }
        })
        .on('typeahead:render typeahead:active typeahead:open', (event) => {
          const parent = event.target.parentElement;
          const dropdownMenu = parent.querySelector('.dropdown-menu');
          const emptySuggestions = dropdownMenu.classList.contains('tt-empty');

          event.currentTarget.setAttribute(
            'aria-expanded',
            emptySuggestions ? 'false' : 'true',
          );

          if (emptySuggestions) {
            const politeElement = parent.querySelector('[aria-live="polite"]');

            if (politeElement) {
              politeElement.innerHTML = '';
            }
          }
        })
        .on('typeahead:asynccancel typeahead:asyncreceive', () => {
          setIsLoading(false);
        });
    } catch (error) {
      errorReporting.captureException(error);
    }
  }, [
    currentUser?.last_location?.country_code,
    currentNetwork?.bias_location?.country_code,
    location.bias_code,
    Engine,
    providerName,
    onlineEnabled,
    onlineLabel,
    updateOnline,
    updateLocationState,
  ]);

  useEffect(() => {
    let maxAttempts = 20;

    const interval = setInterval(() => {
      if (Engine.isReady()) {
        clearInterval(interval);
        initSearch();
      }

      if (maxAttempts === 0) {
        clearInterval(interval);

        errorReporting.captureException(
          new Error(
            `The search engine ${providerName} is not loaded after ${ms(20 * 200, { long: true })}`,
          ),
        );
      }

      maxAttempts -= 1;
    }, 200);

    return () => clearInterval(interval);
  }, [initSearch, Engine, providerName]);

  const defaultValue = location?.address ?? location?.location ?? '';

  return (
    <Wrapper
      className={classNames(className, 'relative')}
      $inputAndDropdownTheme={inputAndDropdownTheme}
    >
      <div
        className={classNames('input-with-icon--left', {
          'input-with-icon': isClearable,
          input: label,
          'input--filled': label && location.lat,
        })}
      >
        {label && <label className="uk-form-label">{label}</label>}

        <div className="relative">
          <div
            className={classNames('absolute', style['custom-error'], {
              [style['custom-error--anim']]: displayError,
            })}
            id={`${id}-custom-error`}
          >
            <span aria-hidden>
              {I18n.t('web.users.search.empty_location_error')}
            </span>
          </div>

          {IconComponent && (
            <IconWrapper
              $iconPaddingValue={iconPaddingValue}
              $inputAndDropdownTheme={inputAndDropdownTheme}
              height={themeInput.iconSize}
              theme={theme}
              width={themeInput.iconSize}
            >
              <IconComponent />
            </IconWrapper>
          )}
          {/* TODO : change to ReactSelectAsyncInput */}
          <StyledInput
            aria-describedby={aggregatedAriaDescribedBy}
            autoComplete="off"
            className={`required uk-input ${inputHtml?.class}`}
            defaultValue={defaultValue}
            disabled={disabled}
            id={id}
            maxLength={255}
            name={`${inputName}[${primaryInputName}]`}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={handleOnKeyPress}
            placeholder={
              displayError && locationInputRef.current?.value
                ? ''
                : placeholder || I18n.t('web.users.search.location')
            }
            ref={locationInputRef}
            type="text"
            theme={theme}
            $inputAndDropdownTheme={inputAndDropdownTheme}
          />

          {isClearable && (
            <IconWrapper
              aria-label={s__('Accessibility|Clean this input value')}
              height={themeInput.iconSize}
              onClick={resetLocationState}
              role="button"
              tabIndex={0}
              theme={theme}
              width={themeInput.iconSize}
              $iconIsClickable
              $iconPaddingValue={iconPaddingValue}
              $inputAndDropdownTheme={inputAndDropdownTheme}
              $rightIcon
            >
              <CloseIcon />
            </IconWrapper>
          )}
        </div>

        {isLoading && (
          <Box position="absolute" right="35px" bottom="10px">
            <Loader size={16} strokeWidth={2} />
          </Box>
        )}

        {additionalInputs && (
          <div>
            <input
              type="hidden"
              name={`${inputName}[colloquial_area]`}
              defaultValue={location.colloquial_area || ''}
            />
            <input
              type="hidden"
              name={`${inputName}[neighborhood]`}
              defaultValue={location.neighborhood || ''}
            />
            <input
              type="hidden"
              name={`${inputName}[sublocality_level_1]`}
              defaultValue={(location.sublocality_level_1 as string) || ''}
            />
            <input
              type="hidden"
              name={`${inputName}[city]`}
              defaultValue={location.city || ''}
            />
            <input
              type="hidden"
              name={`${inputName}[administrative_area_level_1]`}
              defaultValue={location.administrative_area_level_1 || ''}
            />
            <input
              type="hidden"
              name={`${inputName}[administrative_area_level_2]`}
              defaultValue={location.administrative_area_level_2 || ''}
            />
            <input
              type="hidden"
              name={`${inputName}[country]`}
              defaultValue={location.country || ''}
            />
            <input
              type="hidden"
              name={`${inputName}[lat]`}
              defaultValue={location.lat || ''}
            />
            <input
              type="hidden"
              name={`${inputName}[lng]`}
              defaultValue={location.lng || ''}
            />
            <input
              type="hidden"
              name={`${inputName}[location_level]`}
              defaultValue={location.location_level || ''}
            />
            <input
              type="hidden"
              name={`${inputName}[country_code]`}
              defaultValue={location.country_code || ''}
            />
            <input
              type="hidden"
              name={`${inputName}[postal_code]`}
              defaultValue={(location.postal_code as string) || ''}
            />
            {location.lat && (
              <input
                type="hidden"
                name={`${inputName}[provider]`}
                defaultValue={provider}
              />
            )}
            {idAttr && (
              <input
                type="hidden"
                name={`${inputName}[id]`}
                defaultValue={defaultLocation?.id}
              />
            )}
          </div>
        )}
      </div>

      {radius.enabled && !mobileDeviceFromUserAgent.any() && (
        <InputRadius
          originalValue={Math.round(location.distance || 0)}
          inputName={radius.inputName}
          canUpdate={!!(location.lat && location.lng)}
          onUpdateRange={onSelectLocation}
        />
      )}
    </Wrapper>
  );
};

export default withFeatureFlag(
  (props: Omit<InputLocationProps, 'theme' | 'inputAndDropdownTheme'>) => {
    /**
    When this component is loaded via Haml, it often missing the theme.
    Wrapping it in a ThemeProvider fixes that issue. When loaded from
    the FO, the `useTheme` hook will ensure the custom colors are used.
   */
    const defaultTheme = useTheme();
    const inputAndDropdownTheme = useInputAndDropdownTheme();

    return (
      <ThemeProvider theme={defaultTheme}>
        {({ theme }) => (
          <InputLocation
            // ref={componentRef}
            theme={theme}
            inputAndDropdownTheme={inputAndDropdownTheme}
            {...props}
          />
        )}
      </ThemeProvider>
    );
  },
);

const Wrapper = styled.div<{ $inputAndDropdownTheme: InputAndDropdownTheme }>`
  ${({ theme, $inputAndDropdownTheme }) => css`
    & .tt-suggestion {
      color: ${$inputAndDropdownTheme.color} !important;
      background-color: ${$inputAndDropdownTheme.bg as ColorName} !important;
    }

    & .tt-selected {
      color: ${theme.colors.white} !important;
      background-color: ${theme.colors.primary} !important;
    }
  `}
`;

const IconWrapper = styled(Box)<IconWrapperProps>`
  ${focusOutline}

  ${({
    $iconIsClickable,
    $iconPaddingValue,
    $inputAndDropdownTheme,
    $rightIcon,
  }) => css`
    ${$iconIsClickable
      ? `
        cursor: pointer;
        pointer-events: all;
        `
      : `
        cursor: default;
        pointer-events: none;
      `}

    color: ${$inputAndDropdownTheme.iconColor};
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    z-index: 1;
    ${$rightIcon
      ? `
      right: ${$iconPaddingValue / 2}px;
      transform: translate(50%, -50%);
      `
      : `
      left: ${$iconPaddingValue / 2}px;
      transform: translate(-50%, -50%);
      `}
  `}
`;

const StyledInput = styled.input<{
  $inputAndDropdownTheme: InputAndDropdownTheme;
}>`
  ${({ theme, $inputAndDropdownTheme }) => css`
    // To avoid zoom on iOS mobile devices, inputs must have font-size set to 16px on s & m
    font-size: ${theme.fontSizes[3]} !important;
    ${responsive('l')`
      font-size: ${theme.fontSizes[2]} !important;
    `}

    :focus {
      border-color: ${theme.colors.primary} !important;
    }

    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 40px !important;
    background: ${$inputAndDropdownTheme.bg} !important;
    color: ${$inputAndDropdownTheme.color} !important;
    border-color: ${$inputAndDropdownTheme.borderColor} !important;

    &::placeholder {
      color: ${$inputAndDropdownTheme.placeholder} !important;
    }
  `}
`;
