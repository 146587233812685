// Google location types
const googleTypeMap = {
  street_address: 'Street',
  route: 'Street',
  intersection: 'Intersection',
  political: 'Region',
  country: 'Country',
  administrative_area_level_1: 'State/Province',
  administrative_area_level_2: 'County',
  administrative_area_level_3: 'District',
  administrative_area_level_4: 'Township',
  administrative_area_level_5: 'Village',
  colloquial_area: 'Area',
  locality: 'City',
  ward: 'Ward',
  sublocality: 'District',
  sublocality_level_1: 'District',
  sublocality_level_2: 'District',
  sublocality_level_3: 'District',
  sublocality_level_4: 'District',
  sublocality_level_5: 'District',
  neighborhood: 'Neighborhood',
  premise: 'Building',
  subpremise: 'Building',
  postal_code: 'Postal Code',
  natural_feature: 'Natural Feature',
  airport: 'Airport',
  park: 'Park',
  point_of_interest: 'Point of Interest',
};

// Bing location types
const bingTypeMap = {
  Address: 'Street',
  PopulatedPlace: 'City',
  Neighborhood: 'Neighborhood',
  Postcode1: 'Postal Code',
  AdminDivision1: 'State/Province',
  AdminDivision2: 'County',
  CountryRegion: 'Country',
};

// Mapbox location types
const mapboxTypeMap = {
  country: 'Country',
  region: 'State/Province',
  postcode: 'Postal Code',
  district: 'District',
  place: 'City',
  locality: 'Town',
  neighborhood: 'Neighborhood',
  address: 'Street',
  poi: 'Point of Interest',
};

export const formatGoogleType = (type) => googleTypeMap[type] || type;

export const formatBingType = (type) => bingTypeMap[type] || type;

export const formatMapboxType = (type) => mapboxTypeMap[type] || type;
